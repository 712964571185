import moment from "moment";
import { useRouter } from "next/router";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FC, useEffect, useRef, useState } from "react";
import { getMyConsults, getOtherConsults } from "../../functions/getConsults";
import { useSession } from "../../functions/useSession";
import { AWSRumEventTypeEnum, recordEvent } from "../../public/scripts/rum";
import { ConsultReasonForVisitEnum, PaginatedConsultList } from "../../types/consult-api";

export interface withDefaultQueuePollerProps {
    myConsults: PaginatedConsultList | null;
    otherConsults: PaginatedConsultList | null;
    activeList: number;
    newConsultIndicator: number;
    loading: { isTimerRefresh: boolean; myConsults: boolean; otherConsults: boolean };
    methods: {
        setActiveList: (tabIndex: number) => void;
        setConsult: (value?: string[]) => void;
        setIsAsyncConsultsOnly: (value?: boolean) => void;
        setPartner: (value?: string[]) => void;
        setProgram: (value?: string[]) => void;
        setReasonForVisit: (value?: ConsultReasonForVisitEnum[]) => void;
    };
}

const withDefaultQueuePoller = (Component: FC<any>) => (props: any) => {
    const [session] = useSession();
    const sessionUser = session?.user as any;

    const router = useRouter();
    const toast = useRef<Toast | null>(null);

    const [myConsults, setMyConsults] = useState<PaginatedConsultList | null>(null);
    const [otherConsults, setOtherConsults] = useState<PaginatedConsultList | null>(null);
    const [activeList, _setActiveList] = useState(0);
    const [newConsultIndicator, setNewConsultIndicator] = useState(-1);
    const [loading, setLoading] = useState({
        isTimerRefresh: true,
        myConsults: false,
        otherConsults: false,
    });

    const isAsyncConsultsOnlyFilter = useRef<boolean | undefined>();
    const consultFilter = useRef<string[] | undefined>();
    const partnerFilter = useRef<string[] | undefined>();
    const programFilter = useRef<string[] | undefined>();
    const reasonForVisitFilter = useRef<ConsultReasonForVisitEnum[] | undefined>();
    const errorMessage = useRef<string>("");

    const setActiveList = (tabIndex: number) => {
        _setActiveList(tabIndex);
        setNewConsultIndicator(-1);
    };

    const mapErrorMessageToTag = (msg: string) => {
        if (msg == "Unavailable Consult")
            return (
                <span className={"text-sm"}>
                    This consult is no longer available.
                    <Button
                        className="p-button-sm p-button-danger return-to-queue-toast-button"
                        label="Return to Queue"
                        onClick={() => {
                            window.location.assign("/");
                        }}
                    />
                </span>
            );
        else return msg;
    };

    const handleError = (error: any) => {
        if (error.message !== errorMessage.current) {
            errorMessage.current = error.message;
            toast.current?.show({
                severity: "error",
                sticky: true,
                summary: "Uh oh!",
                detail: mapErrorMessageToTag(error.message),
                className: "error-toast",
            });
        }
    };

    const list = useRef<any>();
    list.current = { active: activeList, myConsults, otherConsults };

    useEffect(() => {
        const newLoading = { ...loading };
        newLoading.myConsults = false;
        setLoading(newLoading);
    }, [myConsults]);

    useEffect(() => {
        const newLoading = { ...loading };
        newLoading.otherConsults = false;
        setLoading(newLoading);
    }, [otherConsults]);

    const refresh = async (isTimerRefresh: boolean) => {
        const updateConsults = (
            index: number,
            oldConsults: PaginatedConsultList | null,
            newConsults: PaginatedConsultList,
            updateConsults: (arg: PaginatedConsultList | null) => void
        ) => {
            const hidden = index !== list.current.active;
            if (hidden) {
                const oldGuid = oldConsults?.results?.map((c) => c.guid) ?? [];
                const newGuid = newConsults?.results?.map((c) => c.guid) ?? [];
                const consultsChange = oldGuid.join(",") !== newGuid.join(",");
                if (consultsChange) {
                    setNewConsultIndicator(index);
                }
            }
            updateConsults(newConsults);
        };

        setLoading({ isTimerRefresh, myConsults: true, otherConsults: true });
        try {
            const [myConsultsResult, otherConsultsResult] = await Promise.all([
                // @ts-ignore
                getMyConsults(
                    consultFilter.current,
                    partnerFilter.current,
                    programFilter.current,
                    reasonForVisitFilter.current,
                    isAsyncConsultsOnlyFilter.current
                )
                    .then((result) => {
                        updateConsults(0, list.current.myConsults, result, setMyConsults);
                        return result;
                    })
                    .catch((error) => {
                        handleError(error);
                        setMyConsults({ next: "", previous: "", results: [] });
                        return null;
                    }),
                // @ts-ignore
                getOtherConsults(
                    consultFilter.current,
                    partnerFilter.current,
                    programFilter.current,
                    reasonForVisitFilter.current,
                    isAsyncConsultsOnlyFilter.current
                )
                    .then((result) => {
                        updateConsults(1, list.current.otherConsults, result, setOtherConsults);
                        return result;
                    })
                    .catch((error) => {
                        handleError(error);
                        setOtherConsults({ next: "", previous: "", results: [] });
                        return null;
                    }),
            ]);

            // Additional logic after both async functions have completed
            if (myConsultsResult && otherConsultsResult) {
                if (
                    myConsultsResult.results?.length == 0 &&
                    otherConsultsResult.results?.length == 0 &&
                    consultFilter.current &&
                    consultFilter.current.length > 0
                ) {
                    const error = { message: "Unavailable Consult" };
                    handleError(error);
                }
            }
        } catch (error) {
            handleError(error);
            // Handle unexpected errors
        } finally {
            setLoading({ isTimerRefresh, myConsults: false, otherConsults: false });
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (session && moment.utc(session.expires) > moment()) {
                refresh(true);
            }
        }, 30 * 1000);
        return () => clearInterval(timer);
    }, [router.isReady, router.query, session]);

    useEffect(() => {
        if (!router.isReady) {
            return;
        }
        if (session) {
            const consult = router.query.consult;
            if (consult) {
                consultFilter.current = Array.isArray(consult) ? consult : [consult];
            }
            refresh(true);
            recordEvent(AWSRumEventTypeEnum.ConsultDashboardEntered);
        }
        if (sessionUser && router.query.shortCode) {
            recordEvent(AWSRumEventTypeEnum.ConsultDashboardFiltered, {
                clinicianGuid: sessionUser.clinician.guid,
                shortCode: router.query.shortCode,
            });
        }
    }, [router.isReady, router.query, session]);

    const pollerProps: withDefaultQueuePollerProps = {
        myConsults,
        otherConsults,
        activeList,
        newConsultIndicator,
        loading,

        methods: {
            setActiveList,

            setConsult: (value) => {
                if (consultFilter.current !== value) {
                    consultFilter.current = value;
                    refresh(false);
                }
            },

            setIsAsyncConsultsOnly: (value) => {
                if (isAsyncConsultsOnlyFilter.current !== value) {
                    isAsyncConsultsOnlyFilter.current = value;
                    refresh(false);
                }
            },

            setPartner: (value) => {
                if (partnerFilter.current !== value) {
                    partnerFilter.current = value;
                    refresh(false);
                }
            },

            setProgram: (value) => {
                if (programFilter.current !== value) {
                    programFilter.current = value;
                    refresh(false);
                }
            },

            setReasonForVisit: (value) => {
                if (reasonForVisitFilter.current !== value) {
                    reasonForVisitFilter.current = value;
                    refresh(false);
                }
            },
        },
    };
    return (
        <>
            <Toast ref={toast} />
            <Component {...props} consultsPollerProps={pollerProps} />
        </>
    );
};

export default withDefaultQueuePoller;
