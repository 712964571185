import { useContext } from "react";
import withDefaultQueuePoller from "../components/hoc/withDefaultQueuePoller";
import withNextUpQueuePoller from "../components/hoc/withNextUpQueuePoller";
import withPresenceHeartbeat from "../components/hoc/withPresenceHeartbeat";
import { FeatureToggleContext } from "../context/FeatureToggleContext";
import { isFeatureEnabled } from "../functions/consult/isFeatureEnabled";
import { FeatureNameEnum } from "../hooks/useFeatureToggle";
import { DefaultQueue } from "./defaultQueue";
import nextUpQueue from "./nextUpQueue";

function Index() {
    const { features } = useContext(FeatureToggleContext);

    if (isFeatureEnabled(features, FeatureNameEnum.NextConsultModal)) {
        const NextUpQueueWithPoller = withNextUpQueuePoller(nextUpQueue);
        return <NextUpQueueWithPoller />;
    } else {
        const DefaultQueueWithPoller = withDefaultQueuePoller(DefaultQueue);
        return <DefaultQueueWithPoller />;
    }
}

export default withPresenceHeartbeat(Index);
