import { ProgressSpinner } from "primereact/progressspinner";
import React, { MouseEventHandler } from "react";
import styled from "styled-components";

interface StyledContainerProps {
    isActive: boolean;
    isDisabled: boolean;
}

const Container = styled.div<StyledContainerProps>`
    background: ${(props) => (props.isActive === true ? "#26AEBC" : "white")};
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    color: ${(props) => (props.isActive ? "white" : "#404040")};
    cursor: ${(props) => (props.isActive || props.isDisabled ? "default" : "pointer")};
    margin-bottom: 8px;
    padding: 14px 16px 14px 16px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    font-weight: ${(props) => (props.isActive ? "700" : "")};
    opacity: ${(props) => (props.isDisabled ? "0.6" : "1")};
`;

export function SideNavigationButton(props: {
    isActive: boolean;
    isLoading: boolean;
    text: string;
    count: number;
    onClick?: MouseEventHandler<HTMLDivElement>;
}) {
    return (
        <Container data-testid={props.text} isActive={props.isActive} isDisabled={props.count === 0} onClick={props.onClick} className="p-overlay-badge">
            <div className="flex justify-content-between" style={{ width: "100%", alignItems: "center" }}>
                <div className="mr-2 text-sm" style={{ width: "144px" }}>
                    {props.text}
                </div>
                {props.isLoading && (
                    <div className="text-right" style={{ width: "28px" }}>
                        <ProgressSpinner style={{ width: "1em", height: "1em", color: "red" }} strokeWidth="8" />
                    </div>
                )}
                {!props.isLoading && (
                    <div className="text-right text-sm" style={{ width: "28px" }}>
                        {props.count}
                    </div>
                )}
            </div>
        </Container>
    );
}
