import { Consult, ConsultTags, ConsultTagsTagEnum, ConsultVisitStatusEnum } from "../../consult-api";

export interface ConsultCardProps {
    isDueNow: boolean;
    isInitial: boolean;
    isOnHold: boolean;
    isP1: boolean;
    isP2: boolean;
    consult: Consult;
    index?: number;
    methods: {
        setUnavailableCss: () => string;
    };
    disabled?: boolean;
}

export function create(consult: Consult, tabIndex: Number, index: Number): ConsultCardProps {
    return {
        isDueNow: index == 0 && tabIndex == 0,
        isInitial: !!consult.tags.find((consultTags: ConsultTags) => consultTags.tag === ConsultTagsTagEnum.Initial),
        isOnHold: consult.isPauseAllowed && consult.visitStatus === ConsultVisitStatusEnum.ReadyToResume && consult.reason === "On hold",
        isP1: !!consult.tags.find((consultTags: ConsultTags) => consultTags.tag === ConsultTagsTagEnum.P1),
        isP2: !!consult.tags.find((consultTags: ConsultTags) => consultTags.tag === ConsultTagsTagEnum.P2),
        consult,

        methods: {
            setUnavailableCss: function () {
                switch (consult.visitStatus) {
                    case ConsultVisitStatusEnum.ReadyToResume:
                    case ConsultVisitStatusEnum.Assigned:
                    case ConsultVisitStatusEnum.Unassigned:
                    case ConsultVisitStatusEnum.InProgress:
                    case ConsultVisitStatusEnum.ExternalInProgress:
                    case ConsultVisitStatusEnum.InCall:
                    case ConsultVisitStatusEnum.Waiting:
                    case ConsultVisitStatusEnum.Scheduled:
                        return "";
                    default:
                        return "opacity-60";
                }
            },
        },
    };
}
